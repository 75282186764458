<template>
    <div>
        <div class="comparison-config-wrapper">
            <span class="config-section">
                <span class="config-main-label config-label">Compare my Clinic to Clinics with:</span>
            </span>
            <span class="config-section">
                <span class="config-label">Number of Injectors:</span>
                <el-select class="injectors-select comparison-config-select" popper-class="comparison-config-popper"
                    v-model="selectedInjector" placeholder="Nurses">
                    <el-option v-for="item in injectors" :key="item" :label="item" :value="item">
                    </el-option>
                </el-select>
            </span>

            <span class="config-section">
                <span class="config-label">Geography:</span>
                <el-select class="geo-select comparison-config-select" popper-class="comparison-config-popper"
                    v-model="selectedGeo" placeholder="Geography">
                    <el-option v-for="item in geographyList" :key="item" :label="item" :value="item">
                    </el-option>
                </el-select>
            </span>

            <span class="config-section">
                <span class="config-label">Compare to High Performers:</span>
                <el-checkbox class="config-checkbox" v-model="isHighPerformers"></el-checkbox>
            </span>

            <span class="config-options config-section">
                <el-button class="config-btn revert-option" @click="$emit('revertConfig')">Revert to Default</el-button>
                <el-button class="config-btn apply-option" @click="handleSubmit()" :disabled="!hasSelection">Apply
                    Changes</el-button>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        handleChange: {
            type: Function,
            required: true
        },
        hasSelection: {
            type: Boolean,
            required: true
        },
        selectedConfiguration: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isHighPerformers: this.selectedConfiguration.isHighPerformers,
            selectedGeo: this.selectedConfiguration.selectedGeo,
            geographyList: ['Inner City', 'Outer City', 'Regional'],
            selectedInjector: this.selectedConfiguration.selectedInjector,
            injectors: ['1 Nurse', '2-3 Nurses', '4+ Nurses']
        }
    },
    methods: {
        handleSubmit() {
            this.$emit('applyChanges', {
                selectedGeo: this.selectedGeo,
                selectedInjector: this.selectedInjector,
                isHighPerformers: this.isHighPerformers
            });
        }
    },
    watch: {
        selectedGeo: {
            handler(newVal) {
                if (this.selectedConfiguration.selectedGeo != newVal) {
                    this.handleChange();
                }
            },
            immediate: false,
        },
        selectedInjector: {
            handler(newVal) {
                if (this.selectedConfiguration.selectedInjector != newVal) {
                    this.handleChange();
                }
            },
            immediate: false,
        },
        isHighPerformers: {
            handler(newVal) {
                if (this.selectedConfiguration.isHighPerformers != newVal) {
                    this.handleChange();
                }
            },
            immediate: false,
        },
        selectedConfiguration: {
            handler(newVal) {
                this.isHighPerformers = newVal.isHighPerformers;
                this.selectedGeo = newVal.selectedGeo;
                this.selectedInjector = newVal.selectedInjector;
            },
            immediate: false,
        }
    }
}
</script>
<style>
.comparison-config-wrapper {
    background: #EBF7F4;
    border: 1px solid #E3DDD7;
    border-radius: 0.5rem;
    padding: 5px 20px;
    position: relative;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
}

.comparison-config-wrapper .config-label.config-main-label {
    font-family: 'InterSemiBold';
}

.comparison-config-wrapper .config-label {
    font-size: 12px;
    margin-right: 8px;
}

.comparison-config-wrapper .config-section {
    margin-top: 5px;
    margin-bottom: 5px;
}

.comparison-config-wrapper .config-section:not(.config-options) {
    margin-right: 15px;
}

.comparison-config-select.injectors-select {
    width: 100px;
}

.comparison-config-select.organisation-select {
    width: 150px;
}

.comparison-config-select.geo-select {
    width: 100px;
}

.comparison-config-select .el-input__inner {
    border-radius: 10px;
    height: 20px;
    font-size: 10px;
    color: #0E4333;
    font-family: 'InterSemiBold';
    padding: 12px 10px;
}

.comparison-config-select .el-input__inner,
.comparison-config-select .el-input.is-focus .el-input__inner,
.comparison-config-select .el-input__inner:focus,
.comparison-config-select .el-input__inner:hover {
    border: 1px solid #E3DDD7 !important;
}

.comparison-config-select .el-select__caret {
    color: #0E4333 !important;
    line-height: 20px !important;
}

.comparison-config-popper.el-select-dropdown .selected {
    color: #37695B;
    font-family: 'InterSemiBold';
}

.comparison-config-popper.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    color: #37695B;
    font-family: 'InterSemiBold';
}

.comparison-config-popper.el-select-dropdown .el-select-dropdown__item {
    font-family: 'Inter';
    font-size: 10px;
    height: 25px;
    line-height: 25px;
}

.comparison-config-wrapper .config-checkbox .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #0E4333;
    border-color: #0E4333;
}

.comparison-config-wrapper .config-checkbox .el-checkbox__input.is-focus .el-checkbox__inner,
.comparison-config-wrapper .config-checkbox .el-checkbox__input:focus .el-checkbox__inner,
.comparison-config-wrapper .config-checkbox .el-checkbox__input:hover .el-checkbox__inner {
    border-color: #0E4333;
}

.comparison-config-wrapper .config-btn,
.comparison-config-wrapper .config-btn:focus,
.comparison-config-wrapper .config-btn:hover {
    border-radius: 5px;
    font-size: 12px;
    font-family: 'InterSemiBold';
    padding: 8px 12px;
}

.comparison-config-wrapper .config-btn.revert-option {
    color: #0E4333;
    border: 1px solid #E3DDD7;
    background-color: #fff;
}

.comparison-config-wrapper .config-btn.apply-option {
    color: #fff;
    background-color: #0E4333;
}

.comparison-config-wrapper .config-btn.apply-option.is-disabled {
    opacity: .2;
}

.config-btn.apply-option .config-options {
    position: absolute;
    right: 0;
    top: 8px;
}

.comparison-config-wrapper span.config-options {
    margin-left: auto;
}
</style>
